
















import { Component } from "vue-property-decorator";
import { EconomicLimitStore, CreateEconomicLimitModel } from './store';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaEconomicLimitCreate from './Components/PgaEconomicLimitCreate.vue';
import { CreateEconomicLimitResponse } from '@/services/iOrderService';
import { SharedModule } from '@/feature/Shared/store';

@Component({
	components:
	{
		PgaEconomicLimitCreate,
	}
})
export default class EconomicLimitsPageCreate extends mixins(SharedMixin)
{
	get CreateForbidden() : string
	{
		return EconomicLimitStore.EconomicLimitCreate?.Errors.get('Year')?.pop() ?? "";
	}

	get EconomicLimitCreate() : CreateEconomicLimitModel
	{
		return EconomicLimitStore.EconomicLimitCreate;
	}

	async Create() : Promise<void>
	{
		try
		{
			const response: CreateEconomicLimitResponse = await EconomicLimitStore.CreateEconomicLimit();
			const id = response.Id;
			this.$router.replace({ name: 'economiclimitdetails', params: { id }});
		}
		// eslint-disable-next-line no-empty
		catch(error)
		{
			SharedModule.SET_ALERT_ERROR_MESSAGE(this.CreateForbidden);
			SharedModule.SET_ALERT_IS_VISIBLE(true);
		}
	}

	undo() : void
	{
		this.$router.go(-1);
	}

	async mounted() : Promise<void>
	{
		try
		{
			await EconomicLimitStore.CanCreateEconomicLimit();
		}
		catch(error)
		{
			SharedModule.SET_ALERT_ERROR_MESSAGE(error.get('Year')[0]);
            SharedModule.SET_ALERT_IS_VISIBLE(true);
			this.$router.go(-1);
		}

		await EconomicLimitStore.GetLastEconomicLimitValue();
	}

	beforeDestroy() : void
	{
		EconomicLimitStore.DropEconomicLimitCreate();
	}
}

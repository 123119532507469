
















import { Component } from "vue-property-decorator";
import { EconomicLimitStore, EditEconomicLimitModel } from './store';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaEconomicLimitEdit from './Components/PgaEconomicLimitEdit.vue';
import { SharedModule } from '@/feature/Shared/store';
import { Guid } from 'guid-typescript';

@Component({
	components:
	{
		PgaEconomicLimitEdit
	}
})
export default class EconomicLimitsPageEdit extends mixins(SharedMixin)
{
	get Alert() : boolean
	{
		return !!this.EditForbidden;
	}

	get EditForbidden() : string
	{
		return EconomicLimitStore.EconomicLimitEdit?.Errors.get('Year')?.pop() ?? "";
	}

	get EconomicLimitEdit() : EditEconomicLimitModel
	{
		return EconomicLimitStore.EconomicLimitEdit;
	}

	async Edit() : Promise<void>
	{
		try
		{
			await EconomicLimitStore.EditEconomicLimit();
		}
		// eslint-disable-next-line no-empty
		catch(error)
		{
			let message = (error.get('id')?.[0] ?? "") + "<br>" + this.EditForbidden;
			SharedModule.SET_ALERT_ERROR_MESSAGE(message);
			SharedModule.SET_ALERT_IS_VISIBLE(true);
		}
		finally
		{
			this.$router.go(-1);
		}
	}

	undo() : void
	{
		this.$router.go(-1);
    }
    
	async mounted() : Promise<void>
	{
		const id: string = this.$route.params.id;
		
		try
		{
			await EconomicLimitStore.CanEditEconomicLimit(Guid.parse(id));
		}
		catch (error)
		{
			this.$router.go(-1);	
			SharedModule.SET_ALERT_ERROR_MESSAGE(error.get('id')[0]);
            SharedModule.SET_ALERT_IS_VISIBLE(true);
		}

		await EconomicLimitStore.GetEconomicLimitToEdit(id);
	}
	
	beforeDestroy() : void
	{
		EconomicLimitStore.DropEconomicLimitEdit();
	}
}

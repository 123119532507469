










































































import { Component, Model } from "vue-property-decorator";
import { CreateEconomicLimitCommand } from "@/services/iOrderService";
import { EconomicLimitStore } from "../store";
import SharedMixin from "@/mixins/SharedMixin";
import { mixins } from "vue-class-component";

@Component({})
export default class PgaEconomicLimitCreate extends mixins(SharedMixin) {
	@Model("", { type: Object }) readonly Model!: CreateEconomicLimitCommand;

	get year(): Date
	{
		return new Date();
	}

	get offerLimit(): number
	{
		return this.Model?.OfferLimit ?? 0;
	}
	set offerLimit(value: number) 
	{
		EconomicLimitStore.SET_ECONOMICLIMIT_CREATE_OFFER_LIMIT(parseFloat(value.toString()));
	}

	get purchaseInnerLimit(): number
	{
		return this.Model?.PurchaseInnerLimit ?? 0;
	}
	set purchaseInnerLimit(value: number)
	{
		EconomicLimitStore.SET_ECONOMICLIMIT_CREATE_PURCHASE_INNER_LIMIT(parseFloat(value.toString()));
	}

	get purchaseAnnualLimit(): number 
	{
		return this.Model?.PurchaseAnnualLimit ?? 0;
	}
	set purchaseAnnualLimit(value: number) 
	{
		EconomicLimitStore.SET_ECONOMICLIMIT_CREATE_PURCHASE_ANNUAL_LIMIT(parseFloat(value.toString()));
	}

	get purchaseOuterLimit(): number 
	{
		return this.Model?.PurchaseOuterLimit ?? 0;
	}
	set purchaseOuterLimit(value: number) 
	{
		EconomicLimitStore.SET_ECONOMICLIMIT_CREATE_PURCHASE_OUTER_LIMIT(parseFloat(value.toString()));
	}

	get MOLMin(): number
	{
		return this.Model?.MOLMin ?? 0;
	}
	set MOLMin(value: number)
	{
		EconomicLimitStore.SET_ECONOMICLIMIT_CREATE_MOL_MIN(parseFloat(value.toString()));
	}

	get maxBudgetInnerProjects(): number
	{
		return this.Model?.MaxBudgetInnerProjects ?? 0;
	}
	set maxBudgetInnerProjects(value: number)
	{
		EconomicLimitStore.SET_ECONOMICLIMIT_CREATE_MAX_BUDGET_INNER_PROJECTS(parseFloat(value.toString()));
	}

	get Errors(): Map<string, Array<string>>
	{
		return (EconomicLimitStore.EconomicLimitCreate?.Errors ?? new Map<string, Array<string>>());
	}
}






















import { Component } from "vue-property-decorator";
import { EconomicLimitStore, DetailsEconomicLimitModel } from './store';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";
import { SharedModule } from '@/feature/Shared/store';

import PgaEconomicLimitDetails from './Components/PgaEconomicLimitDetails.vue';
import { SendEmailToPermissionsCommand } from '../../../services/iNotificationService';
import { EmailsStore } from "@/feature/Shared/Email/store";

@Component({
	components:
	{
		PgaEconomicLimitDetails
	}
})
export default class EconomicLimitsPageDetails extends mixins(SharedMixin)
{	
	get EconomicLimitDetails() : DetailsEconomicLimitModel
	{
		return EconomicLimitStore.EconomicLimitDetails;
	}

	get canEdit() : boolean
	{
		if(this.EconomicLimitDetails.EconomicLimitDetailsView)
		{
			const currentDate: Date = new Date(this.EconomicLimitDetails.EconomicLimitDetailsView?.Year as Date) ?? new Date();
			return currentDate.getFullYear() == new Date().getFullYear();
		}
		return false;
	}

	get isApprovable() : boolean
	{
		const isDG: boolean = SharedModule.Permissions.includes("ApproveEconomicLimitDG");
		if(isDG)
		{
			return !this.EconomicLimitDetails.EconomicLimitDetailsView?.ApproveDG ?? false;
		}
		else
		{
			return !this.EconomicLimitDetails.EconomicLimitDetailsView?.ApproveDHRG ?? false;
		}
	}

	async approve() : Promise<void>
	{
		await EconomicLimitStore.ApproveEconomicLimit();
	}

	private requestChanges()
	{
		const sendEmailCommand : SendEmailToPermissionsCommand = 
		{
			PermissionIds: ["2A4F361C-5633-46B3-9C03-02C6677D0005"],
			Subject: `Request changes for EconomicLimit of ${new Date(this.EconomicLimitDetails.EconomicLimitDetailsView?.Year ?? "").getFullYear()}`,
			Body: "",
		};
		EmailsStore.SET_SEND_EMAIL_TO_PERMISSIONS_COMMAND(sendEmailCommand);
		EmailsStore.SET_SHOW_FORM_EMAIL_TO_PERMISSIONS(true);
	}

	async mounted() : Promise<void>
	{
		EconomicLimitStore.DropEconomicLimitDetails();
		const id: string = this.$route.params.id;
		await EconomicLimitStore.EconomicLimitDetailsById(id);
	}

	Edit() : void
	{
		const id = this.EconomicLimitDetails.EconomicLimitDetailsView?.Id ?? "";
		this.$router.push({ name: 'economiclimitedit', params: { id }});
	}

	beforeDestroy() : void
	{
		EconomicLimitStore.DropEconomicLimitDetails();
	}
}

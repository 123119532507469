






















































import { Component } from "vue-property-decorator";
import { EconomicLimitStore } from '../store';
import { EconomicLimitModel } from '@/services/iOrderService';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

@Component({})
export default class PgaUsersTableIndex extends mixins(SharedMixin)
{
    private isLoading = true;
	private columnProps = ['ApproveDG','ApproveDHRG','Year',
		'OfferLimit','PurchaseLimit','PurchaseInnerLimit','PurchaseAnnualLimit','PurchaseOuterLimit',
		'MOLMin','MaxBudgetInnerProjects'];
	private headers: Array<any> =
	[
		{
			text: 'Year',
			align: 'start',
			sortable: true,
			value: 'Year',
			keySearch: "ye",
        },
        {
			text: 'Offer Limit',
			align: 'start',
			sortable: true,
			value: 'OfferLimit',
			keySearch: "ol",
        },
        {
			text: 'Purchase Inner Limit',
			align: 'start',
			sortable: true,
			value: 'PurchaseInnerLimit',
			keySearch: "pil",
        },
        {
			text: 'Purchase Annual Limit',
			align: 'start',
			sortable: true,
			value: 'PurchaseAnnualLimit',
			keySearch: "pal",
        },
        {
			text: 'Purchase Outer Limit',
			align: 'start',
			sortable: true,
			value: 'PurchaseOuterLimit',
			keySearch: "pol",
        },
		{
			text: 'MOL Min',
            allign: 'start',
            sortable: true,
			value: 'MOLMin',
			keySearch: "mm",
        },
		{
			text: 'Max Budget Inner Projects',
            allign: 'start',
            sortable: true,
			value: 'MaxBudgetInnerProjects',
			keySearch: "mbip",
		},
		{
			text: 'Approvation DHRG',
            allign: 'start',
			value: 'ApproveDHRG',
		},
		{
			text: 'Approvation DG',
            allign: 'start',
			value: 'ApproveDG',
		}
	];

	get EconomicLimits(): Array<EconomicLimitModel>
	{
		return EconomicLimitStore.EconomicLimits;
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	rowClicked (item: any) : void
	{
		const id = item.Id;
		this.$router.push({ name: 'economiclimitdetails', params: { id }});
	}

	async mounted() : Promise<void>
	{
        await EconomicLimitStore.AllEconomicLimits();
        
		this.isLoading = false;
	}
}

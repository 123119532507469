



































































import { Component, Model } from "vue-property-decorator";
import { EconomicLimitModel } from '@/services/iOrderService';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

@Component({})
export default class PgaEconomicLimitDetails extends mixins(SharedMixin)
{
	@Model("", { type: Object }) readonly Model!: EconomicLimitModel;

	get year(): Date
	{
		const date: Date = new Date(this.Model?.Year ?? new Date());
		return date;
	}

	get offerLimit(): number
	{
		return this.Model?.OfferLimit ?? 0;
	}
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	set offerLimit(value: number){ }

	get purchaseInnerLimit(): number
	{
		return this.Model?.PurchaseInnerLimit ?? 0;
	}
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	set purchaseInnerLimit(value: number) { }

	get purchaseAnnualLimit(): number
	{
		return this.Model?.PurchaseAnnualLimit ?? 0;
	}
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	set purchaseAnnualLimit(value: number) { }

	get purchaseOuterLimit(): number
	{
		return this.Model?.PurchaseOuterLimit ?? 0;
	}
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	set purchaseOuterLimit(value: number){ }

	get MOLMin(): number
	{
		return this.Model?.MOLMin ?? 0;
	}
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	set MOLMin(value: number){ }

	get maxBudgetInnerProjects(): number
	{
		return this.Model?.MaxBudgetInnerProjects ?? 0;
	}
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	set maxBudgetInnerProjects(value: number){ }

	get approveDG(): boolean
	{
		return this.Model?.ApproveDG ?? false;
	}

	get approveDHRG(): boolean
	{
		return this.Model?.ApproveDHRG ?? false;
	}
}

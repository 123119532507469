import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators'
import store from '@/infrastructure/store'
import '@/App.container';
import { EconomicLimitModel, CreateEconomicLimitCommand, EditEconomicLimitCommand, LastEconomicLimit, CreateEconomicLimitResponse } from '../../../services/iOrderService';

import pgaDiContainer from '@/App.container';
import IOrderService from '@/services/iOrderService';
import OrderService from '@/services/OrderService';
import { SharedModule } from '@/feature/Shared/store';
import { Guid } from 'guid-typescript';

export interface EconomicLimitsState {
    EconomicLimits: Array<EconomicLimitModel>;
    EconomicLimitDetails: DetailsEconomicLimitModel;

    EconomicLimitCreate: CreateEconomicLimitModel;
    EconomicLimitEdit: EditEconomicLimitModel;

    LastEconomicLimit: LastEconomicLimit;
}

export interface CreateEconomicLimitModel {
    CreateEconomicLimitCommand: CreateEconomicLimitCommand | null;
    Errors: Map<string, Array<string>>;
}

export interface EditEconomicLimitModel {
    EditEconomicLimitCommand: EditEconomicLimitCommand | null;
    Errors: Map<string, Array<string>>;
}

export interface DetailsEconomicLimitModel {
    EconomicLimitDetailsView: EconomicLimitModel | null;
}

@Module({ namespaced: true, dynamic: true, store, name: 'EconomicLimitsModule', })
class EconomicLimitsStore extends VuexModule implements EconomicLimitsState {

    private orderService: IOrderService = pgaDiContainer.get<IOrderService>(OrderService);

    EconomicLimits: Array<EconomicLimitModel> = new Array<EconomicLimitModel>();
    EconomicLimitDetails: DetailsEconomicLimitModel =
        {
            EconomicLimitDetailsView: null
        };
    EconomicLimitCreate: CreateEconomicLimitModel =
        {
            CreateEconomicLimitCommand: null,
            Errors: new Map<string, Array<string>>()
        };
    EconomicLimitEdit: EditEconomicLimitModel =
        {
            EditEconomicLimitCommand: null,
            Errors: new Map<string, Array<string>>()
        };
    LastEconomicLimit: LastEconomicLimit =
        {
            Id: '',
            Year: new Date()
        };


    // ECONOMICLIMITS TABLE
    @Action({ rawError: true })
    public async AllEconomicLimits(): Promise<void> {
        const els: Array<EconomicLimitModel> = await this.orderService.GetEconomicLimits();
        this.SET_ECONOMICLIMITS_TABLE(els);
    }
    @Mutation
    public SET_ECONOMICLIMITS_TABLE(els: Array<EconomicLimitModel>) {
        this.EconomicLimits = els;
    }

    // ECONOMICLIMIT DETAILS
    @Action({ rawError: true })
    public async EconomicLimitDetailsById(Id: string): Promise<void> {
        SharedModule.SET_ISLOADING(true);
        const el: EconomicLimitModel = await this.orderService.GetEconomicLimit(Id);

        this.SET_ECONOMICLIMIT_DETAILS(el);
        SharedModule.SET_ISLOADING(false);
    }
    @Action({ rawError: true })
    public DropEconomicLimitDetails() {
        this.SET_ECONOMICLIMIT_DETAILS(null);
    }
    @Mutation
    public SET_ECONOMICLIMIT_DETAILS(economicLimit: EconomicLimitModel | null) {
        this.EconomicLimitDetails.EconomicLimitDetailsView = economicLimit;
    }
    @Mutation
    public SET_ECONOMICLIMIT_DETAILS_ID(Id: string) {
        if (this.EconomicLimitDetails.EconomicLimitDetailsView)
            this.EconomicLimitDetails.EconomicLimitDetailsView.Id = Id;
    }
    @Mutation
    public SET_ECONOMICLIMIT_DETAILS_YEAR(Year: Date) {
        if (this.EconomicLimitDetails.EconomicLimitDetailsView)
            this.EconomicLimitDetails.EconomicLimitDetailsView.Year = new Date(Year);
    }
    @Mutation
    public SET_ECONOMICLIMIT_DETAILS_OFFER_LIMIT(offerLimit: number) {
        if (this.EconomicLimitDetails.EconomicLimitDetailsView)
            this.EconomicLimitDetails.EconomicLimitDetailsView.OfferLimit = offerLimit;
    }
    @Mutation
    public SET_ECONOMICLIMIT_DETAILS_PURCHASE_INNER_LIMIT(purchaseInnerLimit: number) {
        if (this.EconomicLimitDetails.EconomicLimitDetailsView)
            this.EconomicLimitDetails.EconomicLimitDetailsView.PurchaseInnerLimit = purchaseInnerLimit;
    }
    @Mutation
    public SET_ECONOMICLIMIT_DETAILS_PURCHASE_ANNUAL_LIMIT(purchaseAnnualLimit: number) {
        if (this.EconomicLimitDetails.EconomicLimitDetailsView)
            this.EconomicLimitDetails.EconomicLimitDetailsView.PurchaseAnnualLimit = purchaseAnnualLimit;
    }
    @Mutation
    public SET_ECONOMICLIMIT_DETAILS_PURCHASE_OUTER_LIMIT(purchaseOuterLimit: number) {
        if (this.EconomicLimitDetails.EconomicLimitDetailsView)
            this.EconomicLimitDetails.EconomicLimitDetailsView.PurchaseOuterLimit = purchaseOuterLimit;
    }
    @Mutation
    public SET_ECONOMICLIMIT_DETAILS_MOL_MIN(MOLMin: number) {
        if (this.EconomicLimitDetails.EconomicLimitDetailsView)
            this.EconomicLimitDetails.EconomicLimitDetailsView.MOLMin = MOLMin;
    }
    @Mutation
    public SET_ECONOMICLIMIT_DETAILS_MAX_BUDGET_INNER_PROJECTS(maxBudgetInnerProjects: number) {
        if (this.EconomicLimitDetails.EconomicLimitDetailsView)
            this.EconomicLimitDetails.EconomicLimitDetailsView.MaxBudgetInnerProjects = maxBudgetInnerProjects;
    }
    @Mutation
    public SET_ECONOMICLIMIT_DETAILS_APPROVE_DHRG(approveDHRG: boolean) {
        if (this.EconomicLimitDetails.EconomicLimitDetailsView)
            this.EconomicLimitDetails.EconomicLimitDetailsView.ApproveDHRG = approveDHRG;
    }
    @Mutation
    public SET_ECONOMICLIMIT_DETAILS_APPROVE_DG(approveDG: boolean) {
        if (this.EconomicLimitDetails.EconomicLimitDetailsView)
            this.EconomicLimitDetails.EconomicLimitDetailsView.ApproveDG = approveDG;
    }

    // CREATE ECONOMICLIMIT
    @Action({ rawError: true })
    public async CreateEconomicLimit(): Promise<CreateEconomicLimitResponse> {
        try {
            SharedModule.SET_ISLOADING(true);
            if (this.EconomicLimitCreate.CreateEconomicLimitCommand)
                return await this.orderService.CreateEconomicLimit(this.EconomicLimitCreate.CreateEconomicLimitCommand);
            SharedModule.SET_ISLOADING(false);
            return { Id: "" };
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_ECONOMICLIMIT_CREATE_ERRORS(errs);
            throw errs;
        }
    }
    @Action({ rawError: true })
    public async GetLastEconomicLimitValue() {
        SharedModule.SET_ISLOADING(true);
        const lastValue = await this.orderService.GetEconomicLimit(this.LastEconomicLimit.Id);
        delete lastValue.Year;

        this.SET_ECONOMICLIMIT_CREATE(lastValue);
        SharedModule.SET_ISLOADING(false);
    }
    @Action({ rawError: true })
    public DropEconomicLimitCreate() {
        this.SET_ECONOMICLIMIT_CREATE(null);
        this.SET_ECONOMICLIMIT_CREATE_ERRORS(new Map<string, Array<string>>());
    }
    @Mutation
    public SET_ECONOMICLIMIT_CREATE(economicLimit: CreateEconomicLimitCommand | null) {
        this.EconomicLimitCreate.CreateEconomicLimitCommand = economicLimit;
    }
    @Mutation
    public SET_ECONOMICLIMIT_CREATE_OFFER_LIMIT(offerLimit: number) {
        if (this.EconomicLimitCreate.CreateEconomicLimitCommand)
            this.EconomicLimitCreate.CreateEconomicLimitCommand.OfferLimit = offerLimit;
    }
    @Mutation
    public SET_ECONOMICLIMIT_CREATE_PURCHASE_INNER_LIMIT(purchaseInnerLimit: number) {
        if (this.EconomicLimitCreate.CreateEconomicLimitCommand)
            this.EconomicLimitCreate.CreateEconomicLimitCommand.PurchaseInnerLimit = purchaseInnerLimit;
    }
    @Mutation
    public SET_ECONOMICLIMIT_CREATE_PURCHASE_ANNUAL_LIMIT(purchaseAnnualLimit: number) {
        if (this.EconomicLimitCreate.CreateEconomicLimitCommand)
            this.EconomicLimitCreate.CreateEconomicLimitCommand.PurchaseAnnualLimit = purchaseAnnualLimit;
    }
    @Mutation
    public SET_ECONOMICLIMIT_CREATE_PURCHASE_OUTER_LIMIT(purchaseOuterLimit: number) {
        if (this.EconomicLimitCreate.CreateEconomicLimitCommand)
            this.EconomicLimitCreate.CreateEconomicLimitCommand.PurchaseOuterLimit = purchaseOuterLimit;
    }
    @Mutation
    public SET_ECONOMICLIMIT_CREATE_MOL_MIN(MOLMin: number) {
        if (this.EconomicLimitCreate.CreateEconomicLimitCommand)
            this.EconomicLimitCreate.CreateEconomicLimitCommand.MOLMin = MOLMin;
    }
    @Mutation
    public SET_ECONOMICLIMIT_CREATE_MAX_BUDGET_INNER_PROJECTS(maxBudgetInnerProjects: number) {
        if (this.EconomicLimitCreate.CreateEconomicLimitCommand)
            this.EconomicLimitCreate.CreateEconomicLimitCommand.MaxBudgetInnerProjects = maxBudgetInnerProjects;
    }
    @Mutation
    public SET_ECONOMICLIMIT_CREATE_ERRORS(errors: Map<string, Array<string>>) {
        this.EconomicLimitCreate.Errors = errors;
    }

    // EDIT ECONOMICLIMIT
    @Action({ rawError: true })
    public async EditEconomicLimit() {
        try {
            SharedModule.SET_ISLOADING(true);
            if (this.EconomicLimitEdit.EditEconomicLimitCommand)
                await this.orderService.EditEconomicLimit(this.EconomicLimitEdit.EditEconomicLimitCommand.Id, this.EconomicLimitEdit.EditEconomicLimitCommand);
            SharedModule.SET_ISLOADING(false);
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_ECONOMICLIMIT_EDIT_ERRORS(errs);
            throw errs;
        }
    }
    @Action({ rawError: true })
    public async GetEconomicLimitToEdit(id: string) {
        SharedModule.SET_ISLOADING(true);
        const el: EconomicLimitModel = await this.orderService.GetEconomicLimit(id);

        const elCommandEdit: EditEconomicLimitCommand = 
        {
            Id: el.Id,
            Year: el.Year,
            OfferLimit: el.OfferLimit,
            PurchaseAnnualLimit: el.PurchaseAnnualLimit,
            PurchaseInnerLimit: el.PurchaseInnerLimit,
            PurchaseOuterLimit: el.PurchaseOuterLimit,
            MOLMin: el.MOLMin,
            MaxBudgetInnerProjects: el.MaxBudgetInnerProjects,
        }

        this.SET_ECONOMICLIMIT_EDIT(elCommandEdit);
        SharedModule.SET_ISLOADING(false);
    }
    @Action({ rawError: true })
    public DropEconomicLimitEdit() {
        this.SET_ECONOMICLIMIT_EDIT(null);
        this.SET_ECONOMICLIMIT_EDIT_ERRORS(new Map<string, Array<string>>());
    }
    @Mutation
    public SET_ECONOMICLIMIT_EDIT(economicLimit: EditEconomicLimitCommand | null) {
        this.EconomicLimitEdit.EditEconomicLimitCommand = economicLimit;
    }
    @Mutation
    public SET_ECONOMICLIMIT_EDIT_ID(id: string) {
        if (this.EconomicLimitEdit.EditEconomicLimitCommand)
            this.EconomicLimitEdit.EditEconomicLimitCommand.Id = id;
    }
    @Mutation
    public SET_ECONOMICLIMIT_EDIT_YEAR(year: Date) {
        if (this.EconomicLimitEdit.EditEconomicLimitCommand)
            this.EconomicLimitEdit.EditEconomicLimitCommand.Year = year;
    }
    @Mutation
    public SET_ECONOMICLIMIT_EDIT_OFFER_LIMIT(offerLimit: number) {
        if (this.EconomicLimitEdit.EditEconomicLimitCommand)
            this.EconomicLimitEdit.EditEconomicLimitCommand.OfferLimit = offerLimit;
    }
    @Mutation
    public SET_ECONOMICLIMIT_EDIT_PURCHASE_INNER_LIMIT(purchaseInnerLimit: number) {
        if (this.EconomicLimitEdit.EditEconomicLimitCommand)
            this.EconomicLimitEdit.EditEconomicLimitCommand.PurchaseInnerLimit = purchaseInnerLimit;
    }
    @Mutation
    public SET_ECONOMICLIMIT_EDIT_PURCHASE_ANNUAL_LIMIT(purchaseAnnualLimit: number) {
        if (this.EconomicLimitEdit.EditEconomicLimitCommand)
            this.EconomicLimitEdit.EditEconomicLimitCommand.PurchaseAnnualLimit = purchaseAnnualLimit;
    }
    @Mutation
    public SET_ECONOMICLIMIT_EDIT_PURCHASE_OUTER_LIMIT(purchaseOuterLimit: number) {
        if (this.EconomicLimitEdit.EditEconomicLimitCommand)
            this.EconomicLimitEdit.EditEconomicLimitCommand.PurchaseOuterLimit = purchaseOuterLimit;
    }
    @Mutation
    public SET_ECONOMICLIMIT_EDIT_MOL_MIN(MOLMin: number) {
        if (this.EconomicLimitEdit.EditEconomicLimitCommand)
            this.EconomicLimitEdit.EditEconomicLimitCommand.MOLMin = MOLMin;
    }
    @Mutation
    public SET_ECONOMICLIMIT_EDIT_MAX_BUDGET_INNER_PROJECTS(maxBudgetInnerProjects: number) {
        if (this.EconomicLimitEdit.EditEconomicLimitCommand)
            this.EconomicLimitEdit.EditEconomicLimitCommand.MaxBudgetInnerProjects = maxBudgetInnerProjects;
    }
    @Mutation
    public SET_ECONOMICLIMIT_EDIT_ERRORS(errors: Map<string, Array<string>>) {
        this.EconomicLimitEdit.Errors = errors;
    }

    // LAST ECONOMICLIMIT
    @Action({ rawError: true })
    public async GetLastEconomicLimit() {
        const lastEL: LastEconomicLimit = await this.orderService.GetLastEconomicLimit();

        this.SET_LASTEL_ID(lastEL.Id);
        this.SET_LASTEL_YEAR(lastEL.Year);
    }
    @Mutation
    public SET_LASTEL_ID(id: string) {
        this.LastEconomicLimit.Id = id;
    }
    @Mutation
    public SET_LASTEL_YEAR(year: Date) {
        this.LastEconomicLimit.Year = year;
    }

    // CHECK CREATE ECONOMIC LIMIT
    @Action({ rawError: true })
    public async CanCreateEconomicLimit()
    {
        SharedModule.SET_ISLOADING(true);
        try 
        {
            await this.orderService.CheckCreateEconomicLimit();
        }
        catch (error)
        {
            const errs = parseErrors(error);
            throw errs;
        }
        finally
        {
            SharedModule.SET_ISLOADING(false);
        }
    }
    
    // CHECK EDIT ECONOMIC LIMIT
    @Action({ rawError: true })
    public async CanEditEconomicLimit(id: Guid)
    {
        SharedModule.SET_ISLOADING(true);
        try 
        {
            await this.orderService.CheckEditEconomicLimit(id);
        }
        catch (error)
        {
            const errs = parseErrors(error);
            throw errs;
        }
        finally
        {
            SharedModule.SET_ISLOADING(false);
        }
    }

    // APPROVE ECONOMIC LIMIT
    @Action({ rawError: true })
    public async ApproveEconomicLimit()
    {
        SharedModule.SET_ISLOADING(true);
        try 
        {
            if(this.EconomicLimitDetails.EconomicLimitDetailsView == null)
                return Promise.reject("Details EconomicLimit not initialized, try reload the application");
            await this.orderService.ApproveEconomicLimit(Guid.parse(this.EconomicLimitDetails.EconomicLimitDetailsView.Id));
            const isApprovedByDG = SharedModule.Permissions.includes("ApproveEconomicLimitDG");
            if(isApprovedByDG)
                this.SET_ECONOMICLIMIT_DETAILS_APPROVE_DG(true);
            this.SET_ECONOMICLIMIT_DETAILS_APPROVE_DHRG(true);
                
        }
        catch (error)
        {
            const errs = parseErrors(error);
            SharedModule.SET_ALERT_ERROR_MESSAGE(errs.get("id")?.[0] ?? "");
            SharedModule.SET_ALERT_IS_VISIBLE(true);
            throw errs;
        }
        finally
        {
            SharedModule.SET_ISLOADING(false);
        }
    }
}

function parseErrors(error: any): Map<string, Array<string>> {
    const errs = new Map<string, Array<string>>();
    for (const [key, value] of Object.entries(error.response.data)) {
        const messages = value as Array<string>;
        errs.set(key, messages);
    }
    return errs;
}

export const EconomicLimitStore = getModule(EconomicLimitsStore);
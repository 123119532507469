













import { Component } from "vue-property-decorator"
import { EconomicLimitStore } from './store';

import { mixins } from 'vue-class-component';
import SharedMixin from "@/mixins/SharedMixin";

import PgaEconomicLimitsTable from './Components/PgaEconomicLimitsTable.vue';

@Component({
	components:
	{
		PgaEconomicLimitsTable
	}
})
export default class EconomicLimitsIndex extends mixins(SharedMixin)
{

	get canCreate() : boolean
	{
		return EconomicLimitStore.LastEconomicLimit.Year.getFullYear() < new Date().getFullYear(); 
	}

	async mounted() : Promise<void>
	{
		EconomicLimitStore.GetLastEconomicLimit();
	}
	
}














































































import { Component, Model } from "vue-property-decorator";
import { EditEconomicLimitCommand } from '@/services/iOrderService';
import { EconomicLimitStore } from '../store';

import SharedMixin from '@/mixins/SharedMixin';
import { mixins } from "vue-class-component";

@Component({})
export default class PgaEconomicLimitEdit extends mixins(SharedMixin)
{
	@Model("", { type: Object })
	readonly Model!: EditEconomicLimitCommand;
	
	get year(): Date
	{
		const date: Date = new Date(this.Model?.Year ?? new Date());
		return date;
	}

	get offerLimit(): number
	{
		return this.Model?.OfferLimit ?? 0;
	}
	set offerLimit(value: number)
	{
		EconomicLimitStore.SET_ECONOMICLIMIT_EDIT_OFFER_LIMIT(parseFloat(value?.toString() ?? ""));
	}


	get purchaseInnerLimit(): number
	{
		return this.Model?.PurchaseInnerLimit ?? 0;
	}
	set purchaseInnerLimit(value: number)
	{
		EconomicLimitStore.SET_ECONOMICLIMIT_EDIT_PURCHASE_INNER_LIMIT(parseFloat(value?.toString() ?? ""));
	}


	get purchaseAnnualLimit(): number
	{
		return this.Model?.PurchaseAnnualLimit ?? 0;
	}
	set purchaseAnnualLimit(value: number)
	{
		EconomicLimitStore.SET_ECONOMICLIMIT_EDIT_PURCHASE_ANNUAL_LIMIT(parseFloat(value?.toString() ?? ""));
	}


	get purchaseOuterLimit(): number
	{
		return this.Model?.PurchaseOuterLimit ?? 0;
	}
	set purchaseOuterLimit(value: number)
	{
		EconomicLimitStore.SET_ECONOMICLIMIT_EDIT_PURCHASE_OUTER_LIMIT(parseFloat(value?.toString() ?? ""));
	}

	get MOLMin(): number
	{
		return this.Model?.MOLMin ?? 0;
	}
	set MOLMin(value: number)
	{
		EconomicLimitStore.SET_ECONOMICLIMIT_EDIT_MOL_MIN(parseFloat(value?.toString() ?? ""));
	}

	get maxBudgetInnerProjects(): number
	{
		return this.Model?.MaxBudgetInnerProjects ?? 0;
	}
	set maxBudgetInnerProjects(value: number)
	{
		EconomicLimitStore.SET_ECONOMICLIMIT_EDIT_MAX_BUDGET_INNER_PROJECTS(parseFloat(value?.toString() ?? ""));
	}


	get Errors(): Map<string, Array<string>>
	{
		return EconomicLimitStore.EconomicLimitEdit?.Errors ?? new Map<string, Array<string>>();
	}
}
